<template>
  <div class="container_con">
    <el-card>
      <div class="con_title"><span></span> 新销售计划</div>
      <el-form ref="form" :rules="rules" :model="form" label-width="160px">
        <div class="flex">
          <div>
            <el-form-item label="计划日期：" prop="sale_plan_date">
              <el-date-picker v-model="form.sale_plan_date" type="date" value-format="YYYY-MM-DD" placeholder="选择日期"
                style="width:300px">
              </el-date-picker>
            </el-form-item>
            <el-form-item :label="!form.id ? '单据编号前缀：' : '单据编号：'" prop="no">
              <el-select v-model="form.no" v-if="!form.id" placeholder="请选择" style="width: 100%;; margin-right: 15px;"
                filterable>
                <el-option :label="item.value + '__' + item.title" v-for="(item, index) in zjlist" :key="index"
                  :value="item.value"></el-option>
              </el-select>
              <el-input v-if="form.id" disabled style="width: 100%;" v-model="form.no" placeholder="请输入单据编号" />
            </el-form-item>
            <el-form-item label="部门：" prop="department_id">
              <el-cascader style="width:300px" v-model="form.department_id" :options="list"
                :props="{ expandTrigger: 'hover', checkStrictly: true, emitPath: false, value: 'id', label: 'title', children: '_child' }"
                clearable></el-cascader>
            </el-form-item>
            <el-form-item label="业务员：" prop="admin_id">
              <el-select v-model="form.admin_id" placeholder="请选择" style="width: 100%;; margin-right: 15px;" filterable>
                <el-option :label="item.full_name + '(' + item.department_name + ')' + '-' + item.mnemonic_code"
                  v-for="(item, index) in ywlist" :key="index" :value="item.id"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="销往单位:" prop="merchant_id">
              <el-select v-model="form.merchant_id" placeholder="请输入销往单位键字搜索" clearable style="width:300px" filterable
                @change="get_price()" ref="kehu">
                <el-option :label="item.merchant_name + '-' + item.merchant_no + '-' + item.mnemonic_code"
                  v-for="(item, index) in hwlist" :key="index" :value="item.id"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="备注：" prop="remark">
              <el-input v-model="form.remark" placeholder="请输入备注" style="width:300px" />
            </el-form-item>
          </div>
        </div>

        <div class="table_Box">

          <el-table :data="have_sele_arr" class="custom-table" ref="tables" style="width: 100%"
            @cell-contextmenu="contextmenu" @cell-mouse-enter="cellmouseenter" @sort-change="handleSortChange">
            <el-table-column type="index" width="50">
            </el-table-column>
            <el-table-column prop="product_no" label="产品编号" sortable :sort-method="formatterproduct_no" width="120" />
            <el-table-column prop="product_name" label="品名" width="180" sortable :sort-method="formatterproduct_name" />
            <el-table-column prop="model" label="型号规格" />
            <el-table-column prop="manufacturer" label="生产厂家" width="150" />
            <el-table-column prop="pack_unit_name" label="包装单位" />
            <!-- <el-table-column prop="batch_number" label="产品批号" /> -->
            <el-table-column label="产品批号" width="150">
              <template v-slot="scope">
                <div>
                  {{ scope.row.batch_number }} <a href="javascript:;" style="color: #1676e0;"
                    @click="sele_batch(scope.$index)">选择</a>
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="valid_until" label="有效期" />
            <!-- <el-table-column prop="warehouse_name" label="货位" /> -->
            <el-table-column prop="purchasingPrice" label="进价" />
            <el-table-column prop="stock2" label="现有库存" />
            <!-- <el-table-column label="产品批号" width="150">
              <template v-slot="scope">
                <div>
                  <el-input
                    v-model="scope.row.batch_number"
                    size="small" style="width:100%" 
                  ></el-input>
                </div>
              </template>
            </el-table-column>
            <el-table-column label="有效期" width="170">
              <template v-slot="scope">
                <div>
                  <el-date-picker
                    v-model="scope.row.valid_until"
                    type="date" value-format="YYYY-MM-DD"
                    placeholder="长期可不填"
                    style="width:100%"
                  >
                  </el-date-picker>
                </div>
              </template>
            </el-table-column>
            <el-table-column label="货位" width="150">
              <template v-slot="scope">
                <div>
                  <el-select v-model="scope.row.warehouse_id" placeholder="请选择货位" clearable 
                      style="width:100%" filterable>
                      <el-option :label="item.cargo_space_name" v-for="(item,index) in hw2list"
                          :key="index" :value="item.id"></el-option>
                  </el-select>
                </div>
              </template>
            </el-table-column> -->
            <el-table-column label="数量" width="150" sortable :sort-method="formatterproduct_num">
              <template v-slot="scope">
                <div>
                  <!-- <el-input-number v-model="scope.row.product_num" controls-position="right" :min="0"
                    size="small" :ref="`number`+scope.$index"></el-input-number> -->
                  <el-input-number v-model="scope.row.product_num" controls-position="right" :min="0" size="small"
                    :ref="'number' + scope.$index" :autofocus="true" :name="'number'+scope.$index"></el-input-number>
                </div>
              </template>
            </el-table-column>
            <el-table-column label="单价" width="200" sortable :sort-method="formatterproduct_price">
              <template v-slot="scope">
                <div><el-input-number v-model="scope.row.product_price" controls-position="right" :min="0" :step="0.001"
                    size="small" style="width:150px"></el-input-number> 元</div>
              </template>
            </el-table-column>
            <el-table-column label="金额" sortable :sort-method="formattertotal">
              <template v-slot="scope">
                <div style="color: #FF5115;">
                  {{ (scope.row.product_num * scope.row.product_price || 0).toFixed(3) }}
                </div>
              </template>
            </el-table-column>
            <el-table-column label="赠品" width="150">
              <template v-slot="scope">
                <div>
                  <el-checkbox v-model="scope.row.is_gift">设置为赠品</el-checkbox>
                </div>
              </template>
            </el-table-column>
            <el-table-column label="注册证名">
              <template v-slot="scope">
                {{ scope.row.register_cert_name }}
              </template>
            </el-table-column>
            <el-table-column label="注册证号">
              <template v-slot="scope">
                {{ scope.row.register_cert_no }}
              </template>
            </el-table-column>
            <el-table-column fixed="right" label="操作" width="120">
              <template v-slot="scope">
                <el-button type="text" size="small" @click="handleDelete(scope.$index)">删除</el-button>
                <el-button type="text" size="small" @click="contextmenu(scope.row)">产品账页</el-button>
              </template>
            </el-table-column>
          </el-table>

          <div class="table_title flex_ac" style="padding-bottom: 20px;">
            出库产品： <span @click="sele_products">选择产品</span>
            <div class="t_money margin_a">合计金额: <b style="color: red; font: 16px;">{{ get_all(have_sele_arr,
              'product_num', 'product_price') }}</b></div>
          </div>
        </div>


        <div class="con_title"><span></span> 审批信息</div>

        <div class="flex_ac" v-for="(item2, index2) in sh_arr" :key="index2">
          <el-form-item :label="item2.title + '意见：'" style="width:400px">
            <el-select v-model="item2.opinion" placeholder="请选择意见" style="width:100%">
              <el-option label="同意" value="1"></el-option>
              <el-option label="不同意" value="0"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="经办人:" style="width:400px">
            <el-select v-model="item2.admin_id" placeholder="请选择" style="width: width:100% " filterable>
              <el-option :label="item.full_name + '(' + item.department_name + ')'" v-for="(item, index) in rylist"
                v-show="item['is_' + item2.key] == 1" :key="index" :value="item.id"></el-option>
            </el-select>
          </el-form-item>
        </div>
      </el-form>
    </el-card>
  </div>
  <div class="bottom_btn">
    <el-button type="primary" @click="submitForm('form')" :loading="is_ajax">提交</el-button>
    <el-button @click="$router.push('/salesPlan')">取消</el-button>
  </div>
  <selectProducts ref="selectProducts" @after_sele="after_sele_Products" />
  <salesput ref="salesput" />
  <salebatch ref="salebatch" @after_sele="after_sele_batch" />
</template>

<script>
import salesput from "@/components/sales_put";
import salebatch from "@/components/seleBatch";
export default {

  name: "addpurchasePlan",
  components: { salesput, salebatch },
  data() {
    return {
      id: '',
      is_ajax: false,
      form: {

      },
      rules: {
        sale_plan_date: [
          { required: true, message: "计划日期不能为空", trigger: "blur" }
        ],
        no: [
          { required: true, message: "编号前缀不能为空", trigger: "blur" }
        ],
        department_id: [
          { required: true, message: "部门不能为空", trigger: "blur" }
        ],
        admin_id: [
          { required: true, message: "业务员不能为空", trigger: "blur" }
        ],
        merchant_id: [
          { required: true, message: "销往单位不能为空", trigger: "blur" }
        ]
      },
      zjlist: [],
      list: [],
      rylist: [],
      ywlist: [],
      hwlist: [],
      hw2list: [],
      sh_arr: [
        { admin_id: '', opinion: '1', title: '业务部', key: 'salesman' },
        { admin_id: '', opinion: '1', title: '质管部', key: 'quality' },
        { admin_id: '', opinion: '1', title: '销售部', key: 'sale' },
        { admin_id: '', opinion: '1', title: '总经理', key: 'president' }
      ],
      // 已选择产品
      have_sele_arr: [],
      keyDownListener: null,
      sortedData: [],
      focusIndex: 0
    };
  },
  created() {
    this.id = this.$route.query.id || '';
    if (this.id) {
      this.get_data()
    } else {
      this.form.sale_plan_date = this.today_val()
    }
    this.getOptions();
    this.remoteMethod();
    this.remoteMethod2()
  },
  mounted() {
    // this.$store.dispatch('removeKeyDownListener');
    // this.$store.dispatch('addKeyDownListener');   
  },
  computed: {
    newData() {
      return this.$store.state.isKeyDownListenerAdded
    },
  },
  watch: {
    newData(newval, oldval) {
      if (newval) {
        this.sele_products();
        this.$refs.kehu.blur();
      }
    },

  },
  methods: {
    handleSortChange({ column, prop, order }) {
      // 获取排序后的数组
      const sortedData = this.$refs.tables.store.states.data;
      this.sortedData = sortedData
    },
    formatterproduct_no(a, b) {
      return a.product_no.localeCompare(b.product_no)
    },
    formatterproduct_name(a, b) {
      return a.product_name.localeCompare(b.product_name)
    },
    formatterproduct_num(a, b) {
      return Number(a.product_num) - Number(b.product_num);
    },
    formatterproduct_price(a, b) {
      return Number(a.product_price) - Number(b.product_price);
    },
    formattertotal(a, b) {
      return Number((parseFloat(a.product_num) * parseFloat(a.product_price) || 0).toFixed(3)) - Number((parseFloat(b.product_num) * parseFloat(b.product_price) || 0).toFixed(3));
    },
    cellmouseenter(row, column, cell, event) {
      // console.log(row, column, cell, event);
    },
    get_all(arr, k1, k2) {
      let n = 0;
      for (let i = 0; i < arr.length; i++) {
        n += (arr[i][k1] || 0) * (arr[i][k2] || 0)
      }
      return n.toFixed(4)
    },
    get_price(id) {
      let all_ids = [];
      for (let i = 0; i < this.have_sele_arr.length; i++) {
        if (all_ids.indexOf(this.have_sele_arr[i].product_id) == -1) {
          all_ids.push(this.have_sele_arr[i].product_id)
        }
      }
      let ids = id || all_ids;
      if (this.form.merchant_id && ids.length > 0) {
        for (let i = 0; i < ids.length; i++) {
          this.get_item_price(ids[i])
        }
      }
    },
    get_item_price(id) {
      this.$httpGet("/backend/SalePlan/salePlanPrice", { product_id: id, merchant_id: this.form.merchant_id }).then((res) => {
        if (res.status == 200) {
          for (let i = 0; i < this.have_sele_arr.length; i++) {
            if (this.have_sele_arr[i].product_id == id) {
              this.have_sele_arr[i].product_price = res.data || undefined
            }
          }
        } else {
          this.$message.error(res.message);
        }
      }).catch((err) => {
        console.log(err);
      });
    },
    after_sele_batch(e) {
      console.log(e, 'purchasingPrice');
      if (e.row.length > 0) {
        if (e.row.length == 1) {
          this.have_sele_arr[e.index].batch_number = e.row[0].batch_number;
          this.have_sele_arr[e.index].valid_until = e.row[0].valid_until;
          this.have_sele_arr[e.index].warehouse_name = e.row[0].warehouse_name;
          this.have_sele_arr[e.index].stock_id = e.row[0].id;
          this.have_sele_arr[e.index].stock2 = e.row[0].stock;
          this.have_sele_arr[e.index].purchasingPrice = e.row[0].price;
          this.focusIndex = e.index;
          this.$nextTick(() => {
            console.log(this.$refs["number" + e.index], 'this.$refs["number" + e.index].');
            setTimeout(() => {
              const targetRef = this.$refs["number" + e.index];
              if (targetRef) {
                console.log(targetRef, 'targetRef');
                const inputElement = Array.isArray(targetRef) ? targetRef[0] : targetRef;
                if (inputElement && inputElement.focus) {
                  inputElement.focus();
                  console.log(targetRef, 'inputElement.focus',e.index);
                  console.log('Document active element:', document.activeElement);
                  const elements = document.getElementsByName("number" + e.index);  
                  if (elements.length > 0) {  
                    // 如果存在具有指定 name 的元素，则聚焦第一个   
                    elements[0].focus();  
                  }  
                }
              }
              // this.$refs["number" + e.index].focus();
            }, 200);
          })
        } else {
          for (let i = 0; i < e.row.length; i++) {
            let lastRow = {}
            this.have_sele_arr.map((key, kindex) => {
              if (e.row[i].product_id == key.product_id) {
                if (i == 0) {
                  key.batch_number = e.row[i].batch_number;
                  key.valid_until = e.row[i].valid_until;
                  key.warehouse_name = e.row[i].warehouse_name;
                  key.stock_id = e.row[i].id;
                  key.stock2 = e.row[i].stock;
                  key.purchasingPrice = e.row[i].price;
                } else {
                  lastRow = JSON.parse(JSON.stringify(key))
                  lastRow.batch_number = e.row[i].batch_number;
                  lastRow.valid_until = e.row[i].valid_until;
                  lastRow.warehouse_name = e.row[i].warehouse_name;
                  lastRow.stock_id = e.row[i].id;
                  lastRow.stock2 = e.row[i].stock;
                  lastRow.purchasingPrice = e.row[i].price;
                }
              }
            })
            if (i != 0) {
              this.have_sele_arr.push(lastRow)
            }
          }
          this.$nextTick(() => {
            console.log(this.$refs["number" + e.index], 'this.$refs["number" + e.index].');
            // setTimeout(() => {
              const targetRef = this.$refs["number" + e.index];
              if (targetRef) {
                console.log(targetRef, 'targetRef');
                const inputElement = Array.isArray(targetRef) ? targetRef[0] : targetRef;
                if (inputElement && inputElement.focus) {
                  inputElement.focus();
                  console.log(targetRef, 'inputElement.focus',e.index);
                  console.log('Document active element:', document.activeElement);
                  const elements = document.getElementsByName("number" + e.index);  
                  if (elements.length > 0) {  
                    // 如果存在具有指定 name 的元素，则聚焦第一个  
                    elements[0].focus();  
                  } 
                }
              }
              // this.$refs["number" + e.index].focus();
            // }, 200);
          })
        }
      }
      this.setArr(this.have_sele_arr)
    },
    setArr(arr) {
      // 使用Map来收集具有相同id的对象  
      let groupedById = new Map();
      arr.forEach(item => {
        if (groupedById.has(item.product_id)) {
          groupedById.get(item.product_id).push(item);
        } else {
          groupedById.set(item.product_id, [item]);
        }
      });

      // 根据id的顺序重建数组  
      const result = [];
      groupedById.forEach(group => {
        result.push(...group);
      });
      this.have_sele_arr = result
    },
    sele_batch(index) {
      // console.log(index, this.have_sele_arr[index])
      this.$refs.salebatch.init(this.have_sele_arr[index].product_id, index, false);
    },
    contextmenu(row, column, cell, e) {
      // row.product_id
      this.$refs.salesput.init(row.product_id);
      if (e) {
        e.preventDefault();
      }
      return false;
    },
    // 选择产品
    async after_sele_Products(arr) {
      console.log(arr, arr.length, '选择产品');
      let ids = [];
      for (let i = 0; i < arr.length; i++) {
        arr[i].batch_number = '';
        arr[i].valid_until = null;
        arr[i].stock_id = '';
        arr[i].warehouse_name = '';
        ids.push(arr[i].product_id)
      }
      this.get_price(ids)
      let length = this.have_sele_arr.length
      console.log(length, 'length');
      this.have_sele_arr = this.have_sele_arr.concat(arr);
      if (arr.length === 1) {
        let index = this.have_sele_arr.length - 1
        this.$refs.salebatch.init(this.have_sele_arr[index].product_id, index, true);
      } else {
        for (let i = length; this.have_sele_arr.length > i; i++) {
          try {
            await this.$refs.salebatch.init(this.have_sele_arr[i].product_id, i, true);
          } catch (error) {
            
          }
         
        }
      }
    },
    sele_products() {
      if (!this.form.merchant_id) {
        this.$message.error('请先选择销往单位');
        return false;
      }
      let ids = []; // 已选择的id
      for (let i = 0; i < this.have_sele_arr.length; i++) {
        ids.push(this.have_sele_arr[i].id)
        console.log(ids, 'ids');
      }
      this.$refs['selectProducts'].init(ids, false, true)
    },
    /** 删除按钮操作 */
    handleDelete(index) {
      const _this = this;
      this.$confirm('是否确认删除?', "警告", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(function () {
        _this.have_sele_arr.splice(index, 1)
      })
    },
    // 表单重置
    reset() {
      this.form = {
        sale_plan_date: this.today_val(),
        no: this.zjlist.length > 0 ? this.zjlist[0].value : ''
      };
      this.sh_arr = [
        { admin_id: '', opinion: '1', title: '业务部', key: 'salesman' },
        { admin_id: '', opinion: '1', title: '质管部', key: 'quality' },
        { admin_id: '', opinion: '1', title: '销售部', key: 'sale' },
        { admin_id: '', opinion: '1', title: '总经理', key: 'president' }
      ]
      this.have_sele_arr = [];
      if (this.$refs['form']) {
        this.$refs['form'].clearValidate();
      }
    },
    /** 提交按钮 */
    submitForm: function (form) {
      this.$refs[form].validate((valid) => {
        if (valid) {
          if (form == 'form') {
            let _data = { ...this.form };

            let proarr = [];
            if (this.have_sele_arr.length == 0) {
              this.$message.error('请选择产品');
              return false;
            }
            if (this.sortedData.length == 0) {
              for (let i = 0; i < this.have_sele_arr.length; i++) {
                let _item = this.have_sele_arr[i];
                if (!_item.product_num || (!_item.product_price && _item.product_price != 0) || !_item.batch_number || !_item.warehouse_id) {
                  this.$message.error('请完善产品数量、价格等信息');
                  return false;
                } else {
                  proarr.push({
                    product_id: _item.product_id,
                    product_num: _item.product_num,
                    product_price: _item.product_price,
                    amount: parseFloat((_item.product_price * _item.product_num).toFixed(3) || 0),
                    batch_number: _item.batch_number,
                    valid_until: _item.valid_until || null,
                    warehouse_id: _item.warehouse_id,
                    is_gift: _item.is_gift ? 2 : 1,
                    stock_id: _item.stock_id
                  })
                }
              }
            } else {
              for (let i = 0; i < this.sortedData.length; i++) {
                let _item = this.sortedData[i];
                if (!_item.product_num || (!_item.product_price && _item.product_price != 0) || !_item.batch_number || !_item.warehouse_id) {
                  this.$message.error('请完善产品数量、价格等信息');
                  return false;
                } else {
                  proarr.push({
                    product_id: _item.product_id,
                    product_num: _item.product_num,
                    product_price: _item.product_price,
                    amount: parseFloat((_item.product_price * _item.product_num).toFixed(3) || 0),
                    batch_number: _item.batch_number,
                    valid_until: _item.valid_until || null,
                    warehouse_id: _item.warehouse_id,
                    is_gift: _item.is_gift ? 2 : 1,
                    stock_id: _item.stock_id
                  })
                }
              }
            }


            let arr = [];
            for (let i = 0; i < this.sh_arr.length; i++) {
              if (!this.sh_arr[i].admin_id) {
                this.$message.error('请选择' + this.sh_arr[i].title + '经办人');
                return false;
              }
            }
            // 提交
            this.is_ajax = true;
            _data.audit_array = JSON.stringify(this.sh_arr);
            _data.product_array = JSON.stringify(proarr);
            this.$httpPost("/backend/SalePlan/save", _data).then((res) => {
              if (res.status == 200) {
                this.$message.success(this.id ? '更新成功' : '新增成功');
                // if(this.id){
                window.is_need_back = true;
                this.$router.go(-1)
                // }else{
                //     this.$nextTick(()=>{
                //         this.reset();
                //     })
                // }
              } else {
                if (res.data) {
                  let id = res.data;
                  for (let i = 0; i < this.have_sele_arr.length; i++) {
                    if (this.have_sele_arr[i].product_id == id) {
                      this.$message.error('第' + (i + 1) + '行产品' + this.have_sele_arr[i].product_name + '库存不足');
                    }
                  }

                } else {
                  this.$message.error(res.message);
                }

              }
              this.is_ajax = false;
            }).catch((err) => {
              console.log(err);
              this.is_ajax = false;
            });
          }
        }
      });
    },
    get_data() {
      this.$httpGet("/backend/SalePlan/read", { id: this.id }).then((res) => {
        if (res.status == 200) {
          let _data = res.data;
          let proarr = [];
          for (let i = 0; i < _data.sale_plan_product.length; i++) {
            let _item = _data.sale_plan_product[i];
            let _pro = _item.product;
            _pro.id = _item.id;
            _pro.product_id = _item.product_id;
            _pro.product_num = _item.product_num;
            _pro.purchasingPrice = _item.product_price;
            _pro.product_price = parseFloat(_item.sale_price);
            _pro.batch_number = _item.batch_number;
            _pro.valid_until = _item.valid_until;
            _pro.warehouse_id = _item.warehouse_id;
            _pro.is_gift = _item.is_gift == 2 ? true : false;
            _pro.pack_unit_name = _item.pack_unit_name;
            _pro.warehouse_name = _item.warehouse_name;
            _pro.stock_id = _item.stock_id;
            proarr.push(_pro)
          }
          this.have_sele_arr = proarr;

          for (let i = 0; i < _data.sale_plan_audit.length; i++) {
            this.sh_arr[i].admin_id = _data.sale_plan_audit[i].admin_id;
            this.sh_arr[i].opinion = _data.sale_plan_audit[i].opinion.toString()
          }
          this.form = {
            id: _data.id,
            no: _data.sale_plan_no,
            sale_plan_no: _data.sale_plan_no,
            sale_plan_date: _data.sale_plan_date,
            department_id: _data.department_id,
            merchant_id: _data.merchant_id,
            admin_id: _data.admin_id,
            remark: _data.remark
          }
        } else {
          this.$message.error(res.message);
        }
      }).catch((err) => {
        console.log(err);
      });
    },
    remoteMethod(keyword) {
      this.$httpGet("/backend/Merchant/merchant_sale", { size: 1000000, title: keyword }).then((res) => {
        if (res.status == 200) {
          this.hwlist = res.data.data;
        } else {
          this.$message.error(res.message);
        }
      }).catch((err) => {
        console.log(err);
      });
    },
    remoteMethod2(keyword) {
      this.$httpGet("/backend/Warehouse/index", { size: 1000000, title: keyword }).then((res) => {
        if (res.status == 200) {
          this.hw2list = res.data.data;
        } else {
          this.$message.error(res.message);
        }
      }).catch((err) => {
        console.log(err);
      });
    },
    getOptions() {
      this.$httpGet("/backend/SysConfig/index", {
        size: 10000000,
        groups: '销售计划'
      }).then((res) => {
        if (res.status == 200) {
          this.zjlist = res.data.data;
          if (!this.id && this.zjlist.length > 0) {
            this.form.no = this.zjlist[0].value
          }
        } else {
          this.$message.error(res.message);
        }
      }).catch((err) => {
        console.log(err);
      });

      this.$httpGet("/backend/sys.Admin/assistData", {}).then((res) => {
        if (res.status == 200) {
          this.list = res.data.department_list;
          if (!this.id) {
            for (let i = 0; i < this.list.length; i++) {
              if (this.list[i].title == '销售部') {
                this.form.department_id = this.list[i].id
                break;
              }
            }
          }
        } else {
          this.$message.error(res.message);
        }
      }).catch((err) => {
        console.log(err);
      });


      // 获取业务员 ywlist
      this.$httpGet("/backend/sys.Admin/salesmanList", { size: 10000000 }).then((res) => {
        if (res.status == 200) {
          this.ywlist = res.data.data;
          // if(!this.id && this.ywlist.length>0){
          //     this.form.admin_id=this.ywlist[0].id
          // }
        } else {
          this.$message.error(res.message);
        }
      }).catch((err) => {
        console.log(err);
      });

      // 获取经办人
      this.$httpGet("/backend/sys.Admin/handlerList", { size: 10000000 }).then((res) => {
        if (res.status == 200) {
          this.rylist = res.data;
          // 默认选中第一个
          if (!this.id && this.rylist.length > 0) {
            for (let i = 0; i < this.sh_arr.length; i++) {
              if (this.sh_arr[i].admin_id == '') {
                for (let k = 0; k < this.rylist.length; k++) {
                  if (this.rylist[k]['is_' + this.sh_arr[i].key] == '1') {
                    this.sh_arr[i].admin_id = this.rylist[k].id;
                    break;
                  }
                }
              }
            }
          }
        } else {
          this.$message.error(res.message);
        }
      }).catch((err) => {
        console.log(err);
      });
    }
  },
};
</script>

<style lang="scss" scoped>
.table_Box {
  padding-left: 50px;
  font-size: 14px;

  .table_title {
    span {
      color: #1676e0;
      cursor: pointer;
    }
  }

  .el-table {
    margin: 30px 0;
  }
}

.top_box {
  display: flex;
  justify-content: space-around;
}

.examin {
  display: flex;
  justify-content: space-between;
}
 
// /deep/ .hover-row{
//   background-color: rgba(64,158,255,0.5);
// }
// /deep/.custom-table .el-table__row:hover {
//   background-color: rgba(64,158,255,0.5) !important; /* 你想要的背景色 */
// }
/* 选中某行时的背景色*/
/deep/.custom-table tr.current-row>td {
  background-color: #92cbf1 !important;
}

/*鼠标移入某行时的背景色*/
/deep/.custom-table .el-table__body tr:hover>td {
  background-color: rgba(195, 198, 202, 0.5) !important;
  ;
}

/deep/.custom-table .el-table__body tr:hover>td>.cell {
  font-weight: 600;
}
</style>
